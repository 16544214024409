export default {
  "header": "chz",
  "header-type": "cha",
  "close": "chH",
  "close-icon": "chO",
  "header-infos": "chm",
  "title": "chp body-1",
  "declined": "chx",
  "canceled": "chw",
  "approved": "chN",
  "pending": "chv",
  "date": "cho body-2",
  "mileage-icon": "chn",
  "distance-subtitle": "chi body-2",
  "link-icon": "chA",
  "container": "chY",
  "picto": "chh",
  "general": "chf",
  "amount": "chK",
  "calculation-container": "chG",
  "counterparty": "chr body-2",
  "infobox": "chb"
};
