export default {
  "section-container": "chX",
  "disclaimer-section": "chC",
  "trip-title": "chk caption-bold mb-16",
  "google-link-container": "chJ",
  "link-icon": "chs",
  "trip-details": "chF body-2",
  "trip-details-icon": "chg",
  "trip-details-title": "chD",
  "subtitle": "chV caption-bold mb-16",
  "disclaimer": "chL",
  "visualizer": "chT"
};
