export default {
  "header": "cvJ",
  "header-type": "cvs",
  "close": "cvF",
  "close-icon": "cvg",
  "header-infos": "cvD",
  "title": "cvV body-1",
  "declined": "cvL",
  "canceled": "cvT",
  "approved": "cvz",
  "pending": "cva",
  "date": "cvH body-2",
  "container": "cvO",
  "picto": "cvm",
  "picto-status": "cvp",
  "general": "cvx",
  "amount": "cvw",
  "counterparty": "cvN body-2",
  "initiator": "cvv",
  "initiator-avatar": "cvo",
  "avatar-image": "cvn",
  "initiator-id": "cvi",
  "name": "cvA body-2",
  "role": "cvY caption-bold",
  "infobox": "cvh",
  "account-infobox": "cvf cvh",
  "beneficiary-warning": "cvK caption-bold",
  "dates-wrapper": "cvG",
  "dates": "cvr",
  "recurrence": "cvb caption-bold",
  "arrow": "cvy",
  "notify-checkbox": "cvU"
};
