export default {
  "header": "cfC",
  "header-type": "cfk",
  "close": "cfJ",
  "close-icon": "cfs",
  "header-infos": "cfF",
  "title": "cfg body-1",
  "declined": "cfD",
  "canceled": "cfV",
  "approved": "cfL",
  "date": "cfT body-2",
  "container": "cfz",
  "picto": "cfa",
  "request": "cfH",
  "picto-status": "cfO",
  "check": "cfm",
  "stop": "cfp",
  "warning": "cfx",
  "general": "cfw",
  "amount": "cfN",
  "counterparty": "cfv body-2",
  "initiator": "cfo",
  "initiator-avatar": "cfn",
  "avatar-image": "cfi",
  "initiator-id": "cfA",
  "name": "cfY body-2",
  "role": "cfh caption-bold",
  "infobox": "cff",
  "account-infobox": "cfK cff",
  "beneficiary-warning": "cfG caption-bold",
  "dates-wrapper": "cfr",
  "dates": "cfb",
  "recurrence": "cfy caption-bold",
  "arrow": "cfU",
  "notify-checkbox": "cfj"
};
