export default {
  "sidebar": "cHn",
  "body": "cHi",
  "form": "cHA",
  "form-section": "cHY",
  "form-title": "cHh title2",
  "row": "cHf",
  "payment-details": "cHK",
  "col": "cHG",
  "footer": "cHr",
  "footer-button": "cHb"
};
