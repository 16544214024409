export default {
  "referral-invite": "cxe",
  "referral": "cxB",
  "illustration": "cxE",
  "steps": "cxd",
  "step": "cxI",
  "share-referral": "cxt",
  "choice": "cxM",
  "line": "cxP",
  "email": "cxl",
  "email-input": "cxX",
  "input-field--error": "cxC",
  "error-message": "cxk",
  "external-link-icon": "cxJ"
};
