export default {
  "sections": "crD",
  "actions": "crV",
  "search-input": "crL",
  "no-upload": "crT",
  "new-link": "crz body-2",
  "between-links": "cra",
  "action-bar": "crH",
  "empty-state": "crO"
};
