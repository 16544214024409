export default {
  "attachment-viewer": "cYe",
  "hide-sidebar": "cYB",
  "sidebar": "cYE",
  "header": "cYd",
  "header-top": "cYI",
  "header-icon": "cYt",
  "header-amount": "cYM",
  "infobox": "cYP",
  "vat": "cYl",
  "validation": "cYX"
};
