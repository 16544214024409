export default {
  "header-cell": "cpZ",
  "empty": "cpR",
  "header-content": "cpQ caption-bold",
  "active": "cpe",
  "align-right": "cpB",
  "col-8": "cpE",
  "col-7": "cpd",
  "col-5": "cpI",
  "col-4": "cpt"
};
