export default {
  "mileage-validation": "coG",
  "actions": "cor",
  "action-btn": "cob",
  "action-btn-right": "coy",
  "request-mileage-validation": "coU",
  "account-selector": "coj",
  "has-error": "coW",
  "funds-disclaimer": "cou",
  "account-options": "cnS",
  "account-balance": "cnc",
  "warnings": "cnq"
};
