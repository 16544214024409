export default {
  "numbering-container": "czj",
  "flex-row": "czW",
  "automatic-number-label": "czu",
  "icon-tooltip": "caS",
  "fix-width": "cac",
  "dates-container": "caq",
  "with-performance-date": "caZ",
  "date-picker": "caR",
  "label": "caQ",
  "header-text-field-container": "cae",
  "mt-16": "caB",
  "header-text-field": "caE",
  "header-text-field-close-button": "cad",
  "deposit-toggle-container": "caI"
};
