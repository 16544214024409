export default {
  "referral-invite-new": "cpO",
  "referral": "cpm",
  "center": "cpp",
  "illustration": "cpx",
  "share-referral": "cpw",
  "choice": "cpN",
  "line": "cpv",
  "email": "cpo",
  "email-input": "cpn",
  "input-field--error": "cpi",
  "error-message": "cpA",
  "external-link-icon": "cpY"
};
