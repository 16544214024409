export default {
  "header": "cww",
  "header-type": "cwN",
  "close": "cwv",
  "close-icon": "cwo",
  "header-infos": "cwn",
  "title": "cwi body-1",
  "declined": "cwA",
  "canceled": "cwY",
  "approved": "cwh",
  "pending": "cwf",
  "date": "cwK body-2",
  "mileage-icon": "cwG",
  "distance-subtitle": "cwr body-2",
  "link-icon": "cwb",
  "container": "cwy",
  "picto": "cwU",
  "general": "cwj",
  "amount": "cwW",
  "calculation-container": "cwu",
  "counterparty": "cNS body-2",
  "infobox": "cNc"
};
