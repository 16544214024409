export default {
  "container": "cmG",
  "share-panel": "cmr",
  "email-margin": "cmb",
  "message-margin": "cmy",
  "esignature-container": "cmU",
  "esignature-teaser-title": "cmj",
  "esignature-teaser-badge": "cmW",
  "esignature-teaser-body": "cmu",
  "button-container-footer": "cpS",
  "attachments": "cpc",
  "attachment-file": "cpq"
};
