export default {
  "card-details-container": "cAf",
  "title": "cAK caption-bold mb-16",
  "section": "cAG",
  "divider": "cAr",
  "top-divider": "cAb",
  "content": "cAy body-2",
  "description": "cAU",
  "justify-end": "cAj",
  "icon": "cAW ml-8"
};
