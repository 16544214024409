export default {
  "section-container": "coB",
  "disclaimer-section": "coE",
  "trip-title": "cod caption-bold mb-16",
  "google-link-container": "coI",
  "link-icon": "cot",
  "trip-details": "coM body-2",
  "trip-details-icon": "coP",
  "trip-details-title": "col",
  "subtitle": "coX caption-bold mb-16",
  "disclaimer": "coC",
  "visualizer": "cok"
};
