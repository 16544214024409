export default {
  "row": "cGl",
  "active": "cGX",
  "animated": "cGC",
  "fadein-item": "cGk",
  "cell": "cGJ body-2",
  "cell-content": "cGs",
  "subtitle": "cGF caption",
  "no-padding": "cGg",
  "note": "cGD",
  "cell-amount": "cGV",
  "cell-content-amount": "cGL body-1"
};
