export default {
  "wrapper": "crm",
  "beneficiary": "crp",
  "name": "crx body-1",
  "beneficiary-email": "crw body-2",
  "dropdown-icon": "crN",
  "trusted": "crv",
  "account-number": "cro flex body-2",
  "account-icon": "crn",
  "m-chip": "cri",
  "dropdown": "crA body-2",
  "delete-option": "crY",
  "beneficiary-name": "crh"
};
