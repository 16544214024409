export default {
  "wrapper": "cph",
  "tabs": "cpf",
  "tabs-nav": "cpK",
  "tabs-panel": "cpG",
  "tabs-panel-wrapper": "cpr",
  "list-head": "cpb",
  "list": "cpy",
  "footer": "cpU",
  "reward-amount": "cpj",
  "rewarded": "cpW",
  "registered": "cpu",
  "disclaimer": "cxS",
  "empty-state-wrapper": "cxc",
  "empty-state": "cxq",
  "illustration": "cxZ",
  "spinner": "cxR",
  "total-earned": "cxQ"
};
