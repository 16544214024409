export default {
  "header": "coJ",
  "header-type": "cos",
  "close": "coF",
  "close-icon": "cog",
  "header-infos": "coD",
  "title": "coV body-1",
  "declined": "coL",
  "canceled": "coT",
  "approved": "coz",
  "pending": "coa",
  "date": "coH body-2",
  "mileage-icon": "coO",
  "distance-subtitle": "com body-2",
  "link-icon": "cop",
  "container": "cox",
  "picto": "cow",
  "general": "coN",
  "amount": "cov",
  "calculation-container": "coo",
  "counterparty": "con body-2",
  "infobox": "coi"
};
