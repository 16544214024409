export default {
  "header-cell": "cKK",
  "header-content": "cKG caption-bold",
  "active": "cKr",
  "empty": "cKb",
  "request-type": "cKy",
  "status": "cKU",
  "note": "cKj",
  "amount": "cKW"
};
