export default {
  "modal": "cmR",
  "action-container": "cmQ",
  "similar-label": "cme",
  "info-icon": "cmB",
  "container": "cmE",
  "subtitle": "cmd caption-bold",
  "error": "cmI",
  "item-container": "cmt"
};
