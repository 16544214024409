export default {
  "selector": "czY",
  "pension-selector-content": "czh",
  "withholding-tax-selector-content": "czf",
  "withholding-tax-selector-content-row": "czK",
  "power-select-field": "czG",
  "rate-field": "czr",
  "stamp-revenue-field": "czb",
  "dropdown-option": "czy",
  "dropdown-content": "czU"
};
