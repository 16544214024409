export default {
  "row": "cnJ",
  "active": "cns",
  "dot": "cnF",
  "red": "cng",
  "green": "cnD",
  "yellow": "cnV",
  "status": "cnL",
  "align-right": "cnT",
  "animated": "cnz",
  "fadein-item": "cna",
  "cell": "cnH body-2",
  "cell-content": "cnO",
  "amount": "cnm body-2",
  "subtitle": "cnp caption",
  "padding-left": "cnx",
  "padding-right": "cnw",
  "note": "cnN"
};
