export default {
  "wrapper": "ciU",
  "request-header": "cij",
  "request": "ciW",
  "scheduled": "ciu",
  "scheduled-label": "cAS",
  "note": "cAc",
  "note-label": "cAq",
  "note-label-danger": "cAZ",
  "note-content": "cAR",
  "status": "cAQ",
  "transfer-amount": "cAe"
};
