export default {
  "request-expense-report-validation": "chq",
  "account-selector": "chZ",
  "has-error": "chR",
  "actions": "chQ",
  "action-btn": "che",
  "action-btn-success": "chB",
  "warnings": "chE",
  "top-border": "chd",
  "account-selector-subtitle": "chI",
  "action-btn-right": "cht",
  "funds-disclaimer": "chM",
  "account-options": "chP",
  "account-balance": "chl"
};
