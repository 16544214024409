export default {
  "subtitle": "cid",
  "green-text": "ciI",
  "balance-subtitle": "cit",
  "options": "ciM",
  "shadow": "ciP",
  "menu-item": "cil body-2",
  "close-account": "ciX",
  "footer": "ciC",
  "progress": "cik",
  "progress-bar": "ciJ",
  "progress-bar-text": "cis",
  "progress-active": "ciF"
};
