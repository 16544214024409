export default {
  "header-cell": "cnQ",
  "empty": "cne",
  "header-content": "cnB caption-bold",
  "active": "cnE",
  "header-type": "cnd",
  "header-reason": "cnI",
  "header-status": "cnt",
  "header-amount": "cnM",
  "row-sidebar": "cnP",
  "hidden": "cnl",
  "animated": "cnX",
  "fadein-item": "cnC",
  "animated-cell": "cnk"
};
