export default {
  "wrapper": "cxs",
  "tabs": "cxF",
  "tabs-nav": "cxg",
  "tabs-panel-wrapper": "cxD",
  "list-head": "cxV",
  "list": "cxL",
  "footer": "cxT",
  "reward-amount": "cxz",
  "earned": "cxa",
  "pending": "cxH",
  "empty-state-wrapper": "cxO",
  "empty-state": "cxm",
  "illustration": "cxp",
  "error-wrapper": "cxx",
  "error-illustration": "cxw",
  "error-description": "cxN",
  "spinner": "cxv",
  "total-earned": "cxo"
};
