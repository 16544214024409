export default {
  "container": "cat",
  "title-and-trash": "caM",
  "trash": "caP",
  "quantity-amount": "cal",
  "flex-row": "caX",
  "quantity": "caC",
  "quantityInput": "cak",
  "border-left": "caJ",
  "dropdown": "cas",
  "item-row": "caF",
  "unit-price": "cag",
  "price-input": "caD",
  "hidden": "caV",
  "d-none": "caL",
  "dropdown-option": "caT",
  "list-row": "caz",
  "row-title": "caa",
  "row-price": "caH body-2",
  "row-missing-details-container": "caO",
  "row-missing-details-label": "cam body-2",
  "warning-icon": "cap",
  "row-internal-note": "cax",
  "with-internal-note": "caw",
  "dropdown-content": "caN",
  "select-item": "cav"
};
