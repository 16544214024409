export default {
  "invoice-sidebar": "cHy",
  "body": "cHU",
  "box": "cHj",
  "box-header": "cHW",
  "footer": "cHu",
  "footer-button": "cOS",
  "sidebar-box": "cOc",
  "border-top": "cOq",
  "row": "cOZ",
  "status-container": "cOR",
  "due-date-container": "cOQ",
  "mark-as-btn": "cOe",
  "primary-actions": "cOB cOc",
  "primary-action": "cOE body-1",
  "button-icon": "cOd"
};
