export default {
  "attachment-viewer": "cvq",
  "hide-sidebar": "cvZ",
  "sidebar": "cvR",
  "header": "cvQ",
  "header-top": "cve",
  "header-icon": "cvB",
  "header-amount": "cvE",
  "infobox": "cvd",
  "vat": "cvI",
  "validation": "cvt"
};
