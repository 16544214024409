export default {
  "item": "cmM",
  "details": "cmP",
  "flex": "cml",
  "header": "cmX cml",
  "flex-no-gap": "cmC cml",
  "file-name": "cmk",
  "label": "cmJ body-2",
  "self-icon": "cms",
  "with-separator": "cmF",
  "dimmed": "cmg"
};
