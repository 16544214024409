export default {
  "cell-content": "cAC",
  "icon": "cAk",
  "status_check-m": "cAJ",
  "description": "cAs body-1",
  "from": "cAF body-2",
  "from--abort": "cAg",
  "description--abort": "cAD",
  "title": "cAV body-2"
};
