export default {
  "row": "cGT",
  "active": "cGz",
  "dot": "cGa",
  "red": "cGH",
  "green": "cGO",
  "yellow": "cGm",
  "status": "cGp",
  "align-right": "cGx",
  "animated": "cGw",
  "fadein-item": "cGN",
  "cell": "cGv body-2",
  "cell-content": "cGo",
  "amount": "cGn body-2",
  "subtitle": "cGi caption",
  "padding-left": "cGA",
  "padding-right": "cGY",
  "note": "cGh"
};
