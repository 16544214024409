export default {
  "row": "cyT",
  "cell": "cyz",
  "checkbox": "cya",
  "icon": "cyH",
  "creditor-item-container": "cyO",
  "actions-item-container": "cym",
  "due-date": "cyp",
  "amount": "cyx",
  "align-right": "cyw"
};
