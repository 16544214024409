export default {
  "row": "cpl",
  "cell": "cpX",
  "greyed-out": "cpC",
  "struck-through": "cpk",
  "flex-cell": "cpJ",
  "details-container": "cps",
  "overdue": "cpF",
  "align-right": "cpg",
  "customer-name-container": "cpD",
  "icon": "cpV",
  "warning": "cpL",
  "with-separator": "cpT",
  "align-center": "cpz"
};
