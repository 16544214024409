export default {
  "row": "cKu",
  "active": "cGS",
  "dot": "cGc",
  "red": "cGq",
  "green": "cGZ",
  "yellow": "cGR",
  "status": "cGQ",
  "align-right": "cGe",
  "animated": "cGB",
  "fadein-item": "cGE",
  "cell": "cGd body-2",
  "cell-content": "cGI",
  "amount": "cGt body-1",
  "subtitle": "cGM caption",
  "no-padding": "cGP"
};
