export default {
  "payment-details": "cao",
  "label-payment-conditions": "can",
  "dropdown-content": "cai",
  "dropdown-option": "caA",
  "activate-sdd-container": "caY",
  "activate-sdd-header": "cah",
  "selector-toggle-sdd": "caf",
  "selector-toggle-sdd-subtitle": "caK",
  "selector-toggle-content": "caG",
  "selector-toggle": "car",
  "new-badge": "cab",
  "new-badge-activation": "cay",
  "mt-16": "caU",
  "sdd-mandate-select-loading": "caj"
};
