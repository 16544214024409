export default {
  "form": "cOy",
  "container": "cOU",
  "section": "cOj",
  "footer": "cOW",
  "attachment": "cOu",
  "attachment-title": "cmS",
  "attachment-uploader-disabled": "cmc",
  "attachment-overlay": "cmq",
  "attachment-overlay-hidden": "cmZ"
};
