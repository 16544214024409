export default {
  "request-transfer-validation": "cbB",
  "account-selector": "cbE",
  "has-error": "cbd",
  "actions": "cbI",
  "action-btn": "cbt",
  "warnings": "cbM",
  "top-border": "cbP",
  "account-selector-subtitle": "cbl",
  "action-btn-right": "cbX",
  "funds-disclaimer": "cbC",
  "account-options": "cbk",
  "account-balance": "cbJ",
  "request-multi-transfer-validation": "cbs",
  "wrapper": "cbF",
  "warning-message-row": "cbg",
  "multi-transfer-align": "cbD",
  "button-approve": "cbV",
  "button-approve-spinner": "cbL",
  "button-decline": "cbT"
};
