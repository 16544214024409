export default {
  "row": "cAL",
  "empty": "cAT",
  "active": "cAz",
  "animated": "cAa",
  "fadein-item": "cAH",
  "cell": "cAO body-2",
  "cell-content": "cAm",
  "cell-amount": "cAp",
  "cell-content-amount": "cAx body-1"
};
