export default {
  "mileage-validation": "cfS",
  "actions": "cfc",
  "action-btn": "cfq",
  "approve-btn": "cfZ",
  "request-mileage-validation": "cfR",
  "account-selector": "cfQ",
  "has-error": "cfe",
  "funds-disclaimer": "cfB",
  "account-options": "cfE",
  "account-balance": "cfd",
  "warnings": "cfI"
};
