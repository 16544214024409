export default {
  "container": "cza",
  "depositAmount": "czH",
  "flexRow": "czO",
  "depositInput": "czm",
  "dropdown": "czp",
  "itemRow": "czx",
  "line": "czw",
  "estimates-container": "czN",
  "estimates": "czv",
  "dt": "czo",
  "dd": "czn"
};
