export default {
  "mileage-validation": "cNB",
  "actions": "cNE",
  "action-btn": "cNd",
  "action-btn-success": "cNI",
  "action-btn-right": "cNt",
  "request-mileage-validation": "cNM",
  "account-selector": "cNP",
  "has-error": "cNl",
  "funds-disclaimer": "cNX",
  "account-options": "cNC",
  "account-balance": "cNk",
  "warnings": "cNJ"
};
