export default {
  "gap-32": "cTu",
  "fieldset": "czS",
  "date-picker": "czc",
  "with-performance-date": "czq",
  "mt-4": "czZ",
  "clients": "czR",
  "dropdown-option": "czQ",
  "dropdown-content": "cze",
  "select-client": "czB",
  "client-option": "czE",
  "icon-tooltip": "czd",
  "payment-details": "czI",
  "dates-container": "czt",
  "automatic-number-label": "czM",
  "label": "czP",
  "numbering-container": "czl",
  "fix-width": "czX",
  "customer-error": "czC",
  "amount-text": "czk",
  "header-text-field-container": "czJ",
  "header-text-field": "czs",
  "header-text-field-close-button": "czF",
  "mt-16": "czg"
};
