export default {
  "container": "cmN",
  "content": "cmv",
  "input-row": "cmo",
  "email-row": "cmn",
  "button-row": "cmi",
  "btn-edit": "cmA",
  "input-column": "cmY",
  "form-btn-row": "cmh",
  "border-bottom": "cmf",
  "avatar-input": "cmK"
};
