export default {
  "section-container": "cwD",
  "disclaimer-section": "cwV",
  "trip-title": "cwL caption-bold mb-16",
  "google-link-container": "cwT",
  "link-icon": "cwz",
  "trip-details": "cwa body-2",
  "trip-details-icon": "cwH",
  "trip-details-title": "cwO",
  "subtitle": "cwm caption-bold mb-16",
  "disclaimer": "cwp",
  "visualizer": "cwx"
};
