export default {
  "invoice-sidebar": "cHt",
  "body": "cHM",
  "box": "cHP",
  "box-header": "cHl",
  "footer": "cHX",
  "footer-button": "cHC",
  "footer-button-tooltip": "cHk",
  "reduced-fontsize": "cHJ",
  "sidebar-box": "cHs",
  "border-top": "cHF",
  "row": "cHg",
  "status-container": "cHD",
  "due-date-container": "cHV",
  "greyed-out": "cHL",
  "struck-through": "cHT",
  "overdue": "cHz",
  "primary-actions": "cHa cHs",
  "primary-action": "cHH",
  "danger-action": "cHO",
  "button-icon": "cHm",
  "button-label": "cHp body-2",
  "related-credit-note-title": "cHx caption-bold",
  "paid-at-placeholder": "cHw",
  "collapsible-timeline": "cHN",
  "timeline-status-label": "cHv",
  "related-documents": "cHo"
};
