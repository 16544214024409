export default {
  "wrapper": "crb",
  "header": "cry mb-48",
  "form-title": "crU title-2 mb-16",
  "form-subtitle": "crj body-2",
  "form": "crW",
  "section": "cru mb-48",
  "section-title": "cbS title-3 mb-16",
  "form-footer": "cbc",
  "footer-btn": "cbq",
  "transfer-form": "cbZ"
};
