export default {
  "header": "cYD",
  "header-type": "cYV",
  "close": "cYL",
  "close-icon": "cYT",
  "header-infos": "cYz",
  "title": "cYa body-1",
  "declined": "cYH",
  "canceled": "cYO",
  "approved": "cYm",
  "pending": "cYp",
  "date": "cYx body-2",
  "container": "cYw",
  "picto": "cYN",
  "picto-status": "cYv",
  "general": "cYo",
  "amount": "cYn",
  "counterparty": "cYi body-2",
  "initiator": "cYA",
  "initiator-avatar": "cYY",
  "avatar-image": "cYh",
  "initiator-id": "cYf",
  "name": "cYK body-2",
  "role": "cYG caption-bold",
  "infobox": "cYr",
  "account-infobox": "cYb cYr",
  "beneficiary-warning": "cYy caption-bold",
  "dates-wrapper": "cYU",
  "dates": "cYj",
  "recurrence": "cYW caption-bold",
  "arrow": "cYu",
  "notify-checkbox": "chS"
};
