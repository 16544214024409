export default {
  "row": "cNL body-1",
  "active": "cNT",
  "cell": "cNz",
  "empty": "cNa",
  "ellipsis": "cNH",
  "text-secondary": "cNO",
  "transfer-type": "cNm",
  "transfer-type__icon": "cNp",
  "quick-actions": "cNx",
  "amount": "cNw",
  "cell-approve": "cNN",
  "account-select": "cNv",
  "row-compact": "cNo",
  "cell-amount": "cNn",
  "cell-content-receipt": "cNi body-1",
  "cell-date": "cNA",
  "row-sidebar": "cNY",
  "hidden": "cNh",
  "animated": "cNf",
  "fadein-item": "cNK",
  "animated-cell": "cNG",
  "note": "cNr",
  "checkbox": "cNb"
};
