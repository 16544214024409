export default {
  "row": "cxG",
  "active": "cxr",
  "animated": "cxb",
  "fadein-item": "cxy",
  "cell": "cxU",
  "cell-content": "cxj",
  "row-sidebar": "cxW",
  "empty": "cxu",
  "cell-amount": "cwS",
  "subtitle": "cwc caption",
  "hidden": "cwq",
  "cell-approve": "cwZ",
  "quick-actions": "cwR",
  "account-select": "cwQ",
  "note": "cwe",
  "cell-content-amount": "cwB body-1",
  "cell-status": "cwE"
};
