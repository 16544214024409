export default {
  "content": "cig",
  "balance": "ciD",
  "duration": "ciV",
  "green-text": "ciL",
  "balance-subtitle": "ciT",
  "balance-duration": "ciz",
  "footer": "cia",
  "progress": "ciH",
  "progress-bar": "ciO",
  "progress-bar-text": "cim",
  "progress-active": "cip"
};
