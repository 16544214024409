export default {
  "iban": "cre",
  "checkbox": "crB",
  "toggle-content": "crE",
  "beneficiary-name": "crd",
  "category": "crI",
  "beneficiary-email": "crt",
  "beneficiary-form": "crM",
  "beneficiary-email-container": "crP"
};
