export default {
  "header-cell": "cKH",
  "header-content": "cKO caption-bold",
  "active": "cKm",
  "empty": "cKp",
  "align-right": "cKx",
  "request-type": "cKw",
  "requester": "cKN",
  "amount": "cKv"
};
