export default {
  "container": "cOw",
  "main": "cON",
  "preview": "cOv",
  "form": "cOo",
  "scrollable": "cOn",
  "header": "cOi title-1",
  "form-sections": "cOA",
  "form-section": "cOY",
  "footer": "cOh",
  "preview-header": "cOf",
  "centered": "cOK",
  "preview-content-wrapper": "cOG",
  "preview-content": "cOr",
  "payment-link-preview": "cOb"
};
